.event-card {
    display: flex;
    flex-direction: column;
    width: 23%;
    overflow: hidden;
    min-width: 288px;
    border-radius: 5px;
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, .1);
    height: 367px;
    justify-content: space-around;

}

.event-card__body_head {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, .06667);
}

.event-card__org {
    padding-top: 10px;
    font-size: 10px;
    position: relative;

}

.event-card__org::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, .06667);
}


.moreList {
    margin: 25px 0;
    text-align: center;
}


.moreList span {
    border-bottom: 1px solid rgba(0, 0, 0, .6);
    cursor: pointer;
}

.moreList span:hover {
    border-bottom: 1px solid rgba(0, 0, 0, .9);
    color: #000;
    cursor: pointer;
}

.event-card__header {
    width: 100%;
    display: block;
    position: relative;
}


.event-card__category-box {
    position: absolute;
    bottom: 10px;
    left: 5px;
    background-color: #0051a2;
    border-radius: 7px;
    z-index: 2;
}

.event-card__category-box--last {
    position: absolute;
    bottom: 10px;
    left: 5px;
    background-color: #616161;
    border-radius: 7px;
    z-index: 2;
}

.event-card__category-title {
    margin: 0;
    padding: 6px 10px;
    font-size: 12px;
    color: #fff;

}

.event-card__img {
    width: 100%;
    position: relative;
    height: 157px;
    object-fit: cover;
    z-index: 1;
}

.event-card__img--last {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.event-card__date,
.event-card__title,
.event-card__registration {
    display: block;
}

.event-card__date {
    padding: 10px 0;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.event-card__title {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    max-height: 77px;
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    color: #000;
    /* color: var(--brand-color); */
}

.event-card__body {
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #fff;
}

.event-card__registration {

    margin-bottom: 5px;
    padding: 16px 20px 0;
    font-size: 14px;
    border-top: 1px solid rgba(0, 0, 0, .06667);
}

.event-card__registration--opened,
.event-card__registration--close {
    position: relative;
}

.event-card__registration--opened::after {
    content: "";
    position: absolute;
    top: 19px;
    left: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #58e590;
}

.event-card__registration--close::after {
    content: "";
    position: absolute;
    top: 19px;
    left: 0;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #fc3e1e;
}

@media (max-width:820px) {
    .event-card {
        display: flex;
        flex-direction: column;
        width: 44%;
        overflow: hidden;
        /* max-width: 280px; */
        border-radius: 5px;
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, .1);
        height: 337px;
        justify-content: space-between;
    }
}