.event {
  background-color: #fff;
  padding-bottom: 70px;
}

.event-content__title {
  font-size: 60px;
}

.event__additional_file {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.event__additional_file_icon {
  max-width: 40px;
}

/* .event__header {
  background: url("../../img/event_bg.jpg") no-repeat center center fixed;
  background-size: cover;
  width: 100%;
  min-height: 520px;
  position: relative;
  align-items: flex-end;
} */

.event__short-info {
  position: absolute;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.event__register {
  background-color: #ffdf38;
  color: #252525;
  padding: 15px 20px;
  font-size: 26px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

.event__register--close {
  background-color: tomato;
  color: #fff;
  padding: 15px 20px;
  font-size: 26px;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
}

event__register--close .event__register:hover {
  box-shadow: 1px 1px 1px 1px rgba(255, 255, 86, 0.3);
  cursor: pointer;
}

.event__date {
  color: white;
  text-shadow: #080707 1px 0 10px;
}

.color_white {
  color: white;
}

.event__part,
.event__members {
  text-decoration: underline;
}



.event__second_info_box {
  display: flex;
  flex-direction: column;
  padding: 5px 8px;
  background-color: rgba(0, 81, 162, .4);
  border-radius: 4px;
  row-gap: 5px;
}


.event__date {
  font-size: 18px;
}



.speaker__list {
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;

}

.speaker__item {
  display: flex;
  max-width: 30%;
  column-gap: 20px;
}

.event__description {
  font-size: 20px;
  line-height: 28px;
}

.speaker__img-container {
  border-radius: 50%;
  overflow: hidden;
  max-width: 150px;
  max-height: 150px;
}

.speaker__speaker__avatar {
  width: 100%;
}

.speaker__text {
  max-width: 100%;
}

.speaker__name {
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
}

.speaker__position {
  font-size: 18px;
  padding-top: 10px;
}

.speaker__company {
  font-size: 18px;
}

.full-info__list {
  display: flex;
  row-gap: 15px;
  column-gap: 30px;
}

.full-info__item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 30px;

  align-items: center;
  justify-content: center;
  width: 23%;

  text-align: center;
}

.full-info__img-container {
  max-width: 80px;
}

.full-info__img {
  width: 100%;
}

.full-info__description {
  display: flex;
  flex-direction: column;
  row-gap: 7px;
}

.full-info__body {
  font-weight: 500;
  font-size: 18px;
}