/* filters */


.filters__wrapper {
    max-width: 1230px;
    margin: 0 auto;
    padding: 10px 10px;
}

.filters__header {
    display: flex;
    column-gap: 20px;
    row-gap: 15px;
    align-items: center;
    justify-content: end;
}

.filters-category__list {
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
}



.filters__search-input-box {
    display: flex;
    background-color: #fff;
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    position: relative;
    width: 220px;
    order: 2;
}

.filters__search-input {
    border: none;
    padding: 7px 5px;
    position: relative;
    width: 100%;
}

.icon_type_search::after {
    content: "";
    position: absolute;
    top: 5px;
    right: 6px;
    height: 30px;
    width: 20px;
    background: url('./../../img/icons/search-icon.svg') no-repeat;
    /* background-color: #0051a2; */
}


.filters__list-wrapper {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    padding: 0;
    row-gap: 12px;
}

.filters__body {
    background-color: #e2e2e2;

}

/* .filters__tabs-container {
    width: 100%;
} */

.filters__tabs,
.filters__list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 12px;

}

.filters__list {
    column-gap: 20px;
}

.filters__select {
    border: 1px solid #b7b7b7;
    padding: 4px 5px;
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    width: 220px;
    order: 1;
}

@media (max-width:750px) {
    .filters__header {
        flex-direction: column;
    }

    .filters__search-input-box {
        order: -1;
    }
}