.notification {
    display: flex;
    position: relative;
    justify-content: center;

}

.p_box {
    position: relative;
}

.vissible_btn {
    position: absolute;
    right: 10px;
}

.none {
    display: none;
}

.vissible {
    display: inline;
}

.success {
    text-align: center;
    color: #fff;
    position: absolute;
    top: -50px;
    width: 50%;
    background-color: #0051a2;
    padding: 4px;
    border-radius: 3px;
    opacity: .8;
}



.danger {
    text-align: center;
    color: #fff;
    position: absolute;
    top: -50px;
    width: 100%;
    background-color: tomato;
    opacity: .8;
}