.registration {
    display: flex;
    align-items: center;
    justify-content: center;
}

.registration_content__title {
    display: flex;
    flex-direction: column;

    font-size: 38px;
    padding: 15px;
    text-align: center;
    margin-bottom: 40px;
}

.registration__form {
    display: flex;
    margin: 0 auto;
}

.register {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    width: 300px;
    margin-bottom: 70px;

}

.success-i input,
.success-i textarea,
.success-i ul,
.success-i select {
    border-color: #09c372;
}

.error input,
.error textarea,
.error ul,
.error select {
    /* border-color: #ff3860; */
    border: 1px solid #ff3860;
}

.message__text {
    font-size: 13px;
    color: tomato;
}



.register__block {
    display: flex;
    flex-direction: column;
    row-gap: 10px;

}

.register__input {
    border: 1px solid #ced4da;
    padding: 0.375rem 0.75rem;
    /* padding: 7px 14px; */
    display: block;
    max-width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--darkgrey);
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem
}

.register__required {
    color: tomato;
}



.register__btn {
    font-size: 18px;
}

.register__select {
    width: 100%;
    border-radius: 5px;
    border: 1px solid #ced4da;
    padding: 8px 9px;
    background-color: #fff;
}


/* Message*/

.message {
    display: flex;
    flex-direction: column;
}

.message__title {
    font-size: 26px;
    font-weight: 700;
    text-align: center;
}

.message__text {
    text-align: center;
    font-weight: 500;
}