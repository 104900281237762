/* Reset and base styles  */

*,
*::before,
*::after {
    box-sizing: border-box;
}

:focus,
:active {
    /*outline: none;*/
}

a:focus,
a:active {
    /* outline: none;*/
}

/* Links */

a,
a:link,
a:visited {
    /* color: inherit; */
    text-decoration: none;
}

.heading-reset {
    margin: 0;
    padding: 0;
}

a:hover {
    color: inherit;
    text-decoration: none;
}

/* Common */

aside,
nav,
footer,
header,
section,
main {
    display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    font-size: inherit;
    font-weight: inherit;
}

/* ul,
ul li {
  list-style: none;
} */

img {
    vertical-align: top;
}

img,
svg {
    max-width: 100%;
    height: auto;
}

address {
    font-style: normal;
}

/* Form */

input,
textarea,
button,
select {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    background-color: transparent;
}

input::-ms-clear {
    display: none;
}

button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}

/* Remove excess padding and border in Firefox 4+ */
&::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* button,
input[type="submit"] {
  display: inline-block;
  box-shadow: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
} */

input:focus,
input:active,
button:focus,
button:active {
    /* outline: none; */
}

button::-moz-focus-inner {
    padding: 0;
    border: 0;
}

label {
    cursor: pointer;
}

legend {
    display: block;
}

/* custom reset styles */

.list-reset {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.txt-danger {
    display: none;
    font-size: 12px;
    color: tomato;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    clip: rect(0 0 0 0);
    overflow: hidden;
}