/* HEADER*/

.header {
    padding: 15px 0;
    position: relative;
    background-color: #fff;
    margin-bottom: 20px;
}

.header::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: #ffd800;
    /* opacity: 0.2; */
}

.header__top-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__logo {
    display: flex;
    align-items: center;
    column-gap: 20px;
    row-gap: 20px;
    max-width: 550px;
}

.logo__img {
    width: 100px;
    vertical-align: middle;
}

.header__logo,
.header__logo:hover {
    display: flex;
    color: var(--brand-color)
}

.logo__desc-box {
    margin: 0;
    padding: 0;
}

.logo__desc-title-2 {
    line-height: 22px;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 600;
}

/* NAV menu */

.menu-top {
    display: flex;
    column-gap: 20px;
}

.menu-list__link,
.menu-list__link:hover {
    color: var(--brand-color)
}



@media (max-width:750px) {

    /*
        header
    */

    .logo__desc-box {
        display: none;
        ;
    }
}